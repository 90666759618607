import { Component, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, FormControl } from "@angular/forms";
import { MembersService } from "../../services/members/members.service"; //import
import Swal, { SweetAlertType } from "sweetalert2";
import { concat, Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { MemberList } from "../../models/members/member-list";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-dashbaord",
  templateUrl: "./dashbaord.component.html",
  styleUrls: ["./dashbaord.component.css"],
})
export class DashbaordComponent implements OnInit {
  public memberForm: FormGroup;

  memberList: any;

  memberLoading = false;
  member$: Observable<MemberList[]>;
  memberInput$ = new Subject<string>();
  selectedMember: any;

  Member: number;
  MemberName: string;
  profileImage: SafeResourceUrl;

  constructor(
    private memberService: MembersService,
    private readonly sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) {
    this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
      "../../../assets/images/profile-img.jpg"
    );
    this.memberList = {
      Id: 0,
      Name: "",
      Age: "",
      DateOfBirth: "",
      KuwaitMobile: "",
      IndiaMobile: "",
      Status: "",
      FamilyDetails: [],
    };
  }

  ngOnInit() {
    this.loadMembers();
    this.memberForm = this.formBuilder.group({
      Member: new FormControl({
        Id: 0,
        Name: "",
      }),
      MemberName: new FormControl(""),
    });
  }

  private loadMembers() {
    this.member$ = concat(
      of([]),
      this.memberInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.memberLoading = true)),
        switchMap((term) =>
          this.memberService.getMembersList(term).pipe(
            catchError(() => of([])),
            tap(() => (this.memberLoading = false))
          )
        )
      )
    );
  }

  getMember() {
    this.memberService
      .getMemberDetailsWithFamily(this.memberForm.value.Member.Id)
      .subscribe(
        (response: any) => {
          this.memberList = response;
          console.log(this.memberList);
          this.downloadProfilePicture();
        },
        (err: any) => {
          this.displayFeedback(
            "error",
            "Error!",
            "Something went wrong. Try again!"
          );
        }
      );
  }

  reset() {
    this.memberForm.patchValue({
      Member: {
        Id: 0,
        Name: "",
      },
      MemberName: "",
    });
  }

  downloadProfilePicture() {
    let attachType: string;
    let attachName: string;

    this.memberService
      .downloadProfilePicture(this.memberForm.value.Member.Id)
      .subscribe((response: any) => {
        const resBlob = new Blob([response], { type: "image/jpeg" });
        this.profileImage = this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(resBlob)
        );
      });
  }

  displayFeedback(stype: SweetAlertType, stitle: string, stext: string) {
    Swal.fire({
      toast: true,
      type: stype,
      title: stitle,
      text: stext,
      showConfirmButton: false,
      position: "top",
      timer: 3000,
    });
  }

  displayHTMLFeedback(stype: SweetAlertType, stitle: string, shtml: string) {
    Swal.fire({
      type: stype,
      title: stitle,
      html: shtml,
      showConfirmButton: true,
    });
  }
}
