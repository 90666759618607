import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { MemberList } from "../../models/members/member-list";

@Injectable({
  providedIn: "root",
})
export class MembersService {
  private httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };

  private httpExportOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
    responseType: "blob" as const,
  };

  constructor(private http: HttpClient) {}

  getMemberDetailsWithFamily(memberId: number): Observable<any> {
    const params = new HttpParams().set("memberId", memberId.toString());
    return this.http.get<any>(
      environment.root + "/Member/GetMemberDetailsWithFamily/",
      {
        params,
      }
    );
  }

  getMembersList(term: string): Observable<MemberList[]> {
    const params = new HttpParams().set("filter", term);
    return this.http.get<MemberList[]>(
      environment.root + "/Member/GetActiveMembersList/",
      { params }
    );
  }

  downloadProfilePicture(member: number) {
    return this.http
      .post(
        environment.root + "/Member/DownloadProfilePicture/" + member,
        {},
        this.httpExportOptions
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(error);
    }
    return throwError(error.error.message);
  }
}
