export const environment = {
  production: false,
  root: "https://vendors.kkma.net/kkmavms-api/api",
  fileSystem: "https://vendors.kkma.net/kkmavms-api/filesystem",
  //root: "http://localhost:57333/api",

  //for demo
  // root: "https://demo.fsuite.net/kkma-api/api",
  // fileSystem: "https://demo.fsuite.net/kkma-api/filesystem",
};
//http://localhost:54475/api
